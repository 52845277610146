import {
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../../services/exports/Constants';

export default {
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
  },
  toasts: {
    no_delivery_quotes_available: 'Es tut uns leid, aber wir können keinen Treiber für diese Bestellung finden',
  },
};
